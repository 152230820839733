.box{
    position: relative;
    overflow: hidden;
}
.box:before {
    content: "";
    top: -300px;
    right: -250px;
    position:absolute;
    width:500px;
    height:400px;
    transform:rotate(35deg);
    background-color: #0d4192; /* to see where it is */
}

.box:after {
    content: "";
    bottom: -300px;
    left: -250px;
    position:absolute;
    width:500px;
    height:400px;
    transform:rotate(35deg);
    background-color:#0d4192; /* to see where it is */
}
